import { colors } from '@dixa/tokens';

export const Input = {
  variants: {
    outline: {
      field: {
        borderColor: 'gray.300',
        _invalid: {
          boxShadow: `0 0 0 2px ${colors.red[200]}`,
          borderColor: colors.red[600],
        },
        _focusVisible: {
          boxShadow: `0 0 0 2px ${colors.purple[300]}`,
          borderColor: colors.purple[500],
        },
        _focus: {
          boxShadow: `0 0 0 2px ${colors.purple[300]}`,
          borderColor: `${colors.purple[500]}`,
        },
      },
      addon: {
        '&:focus-within': {
          boxShadow: `0 0 0 2px ${colors.purple[300]}`,
          borderColor: `${colors.purple[500]}`,
          '& > *': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        minHeight: '40px',
      },
      addon: {
        minHeight: '40px',
      },
    },
  },
};
