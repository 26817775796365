import type { AppProps } from 'next/app';
import { IntlProvider } from 'react-intl';
import { NextPage } from 'next';
import { ReactElement, ReactNode } from 'react';
import { theme, ThemeProvider } from '@designSystem';

type NextPageWithLayout = NextPage & {
  /** Layout component to use as parent for page component */
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  /** Component to be rendered on route */
  Component: NextPageWithLayout;
};

export default function MyApp({
  Component,
  pageProps,
  locale = process.env.NEXT_PUBLIC_LOCALE,
}: AppPropsWithLayout & { locale?: string }) {
  // getLayout wraps page in layout component if specified
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <IntlProvider
      locale={locale ?? 'en-US'}
      defaultLocale={'en-US'}
      messages={pageProps.messages}
    >
      <ThemeProvider theme={theme}>
        {getLayout(<Component {...pageProps} />)}
      </ThemeProvider>
    </IntlProvider>
  );
}
