import * as RadixSelect from '@radix-ui/react-select';
import {
  chakra,
  forwardRef,
  ThemingProps,
  HTMLChakraProps,
  useMultiStyleConfig,
  useStyles,
  StylesProvider,
} from '@chakra-ui/react';

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBlock: 1,
  _hover: {
    backgroundColor: '#e7ecf3',
  },
};

const StyledSelect = chakra(RadixSelect.Root);

const StyledSelectTrigger = chakra(RadixSelect.Trigger);

const StyledSelectValue = chakra(RadixSelect.Value);

const StyledSelectIcon = chakra(RadixSelect.Icon);

const StyledSelectContent = chakra(RadixSelect.Content, {
  baseStyle: {
    background: 'white',
    borderRadius: 'md',
    padding: 1,
    boxShadow:
      '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  },
});

const StyledSelectViewport = chakra(RadixSelect.Viewport);

const StyledSelectGroup = chakra(RadixSelect.Group);

const StyledSelectItem = chakra(RadixSelect.Item, {
  baseStyle: {
    paddingInline: 8,
    paddingBlock: 1,
    userSelect: 'none',
    _hover: {
      outline: 'none',
      background: '#e7ecf3',
    },
    _focus: {
      outline: 'none',
      background: '#e7ecf3',
    },
    '&[data-state=active]': {
      background: '#e7ecf3',
    },
  },
});

const StyledSelectItemText = chakra(RadixSelect.ItemText);

const StyledSelectItemIndicator = chakra(RadixSelect.ItemIndicator, {
  baseStyle: {
    position: 'absolute',
    left: 2,
  },
});

const StyledSelectLabel = chakra(RadixSelect.Label);

const StyledSelectSeparator = chakra(RadixSelect.Separator);

const StyledSelectScrollUpButton = chakra(RadixSelect.ScrollUpButton, {
  baseStyle: scrollButtonStyles,
});

const StyledSelectScrollDownButton = chakra(RadixSelect.ScrollDownButton, {
  baseStyle: scrollButtonStyles,
});

export type SelectProps = RadixSelect.SelectProps & ThemingProps<'Select'>;

export const Select = forwardRef<SelectProps, 'div'>((props, ref) => {
  const styles = useMultiStyleConfig('Select', {});
  return (
    <StyledSelect ref={ref} __css={styles.root} {...props}>
      <StylesProvider value={styles}>{props.children}</StylesProvider>
    </StyledSelect>
  );
});

export type SelectTriggerProps = RadixSelect.SelectTriggerProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'button'>;

export const SelectTrigger = forwardRef<SelectTriggerProps, 'button'>(
  (props: SelectTriggerProps, ref) => (
    <StyledSelectTrigger __css={useStyles().trigger} ref={ref} {...props} />
  ),
);

export type SelectValueProps = RadixSelect.SelectValueProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'span'>;

export const SelectValue = forwardRef<SelectValueProps, 'span'>(
  (props: SelectValueProps, ref) => (
    <StyledSelectValue __css={useStyles().value} ref={ref} {...props} />
  ),
);

export type SelectIconProps = RadixSelect.SelectIconProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'svg'>;

export const SelectIcon = forwardRef<SelectIconProps, 'svg'>(
  (props: SelectIconProps, ref) => (
    <StyledSelectIcon __css={useStyles().icon} ref={ref} {...props} />
  ),
);

export type SelectContentProps = RadixSelect.SelectContentProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'div'>;

export const SelectContent = forwardRef<SelectContentProps, 'div'>(
  (props: SelectContentProps, ref) => (
    <StyledSelectContent __css={useStyles().content} ref={ref} {...props} />
  ),
);

export type SelectViewportProps = RadixSelect.SelectViewportProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'div'>;

export const SelectViewport = forwardRef<SelectViewportProps, 'div'>(
  (props: SelectViewportProps, ref) => (
    <StyledSelectViewport __css={useStyles().viewport} ref={ref} {...props} />
  ),
);

export type SelectGroupProps = RadixSelect.SelectGroupProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'div'>;

export const SelectGroup = forwardRef<SelectGroupProps, 'div'>(
  (props: SelectGroupProps, ref) => (
    <StyledSelectGroup __css={useStyles().group} ref={ref} {...props} />
  ),
);

export type SelectItemProps = RadixSelect.SelectItemProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'div'>;

export const SelectItem = forwardRef<SelectItemProps, 'div'>(
  (props: SelectItemProps, ref) => (
    <StyledSelectItem __css={useStyles().item} ref={ref} {...props} />
  ),
);

export type SelectItemTextProps = RadixSelect.SelectItemTextProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'span'>;

export const SelectItemText = forwardRef<SelectItemTextProps, 'span'>(
  (props: SelectItemTextProps, ref) => (
    <StyledSelectItemText __css={useStyles().itemText} ref={ref} {...props} />
  ),
);

export type SelectItemIndicatorProps = RadixSelect.SelectItemIndicatorProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'span'>;

export const SelectItemIndicator = forwardRef<SelectItemIndicatorProps, 'span'>(
  (props: SelectItemIndicatorProps, ref) => (
    <StyledSelectItemIndicator
      __css={useStyles().itemIndicator}
      ref={ref}
      {...props}
    />
  ),
);

export type SelectLabelProps = RadixSelect.SelectLabelProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'div'>;

export const SelectLabel = forwardRef<SelectLabelProps, 'div'>(
  (props: SelectLabelProps, ref) => (
    <StyledSelectLabel __css={useStyles().label} ref={ref} {...props} />
  ),
);

export type SelectSeparatorProps = RadixSelect.SelectSeparatorProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'div'>;

export const SelectSeparator = forwardRef<SelectSeparatorProps, 'div'>(
  (props: SelectSeparatorProps, ref) => (
    <StyledSelectSeparator __css={useStyles().separator} ref={ref} {...props} />
  ),
);

export type SelectScrollUpButtonProps = RadixSelect.SelectScrollUpButtonProps &
  ThemingProps<'Select'> &
  HTMLChakraProps<'div'>;

export const SelectScrollUpButton = forwardRef<
  SelectScrollUpButtonProps,
  'div'
>((props: SelectScrollUpButtonProps, ref) => (
  <StyledSelectScrollUpButton
    __css={useStyles().scrollUpButton}
    ref={ref}
    {...props}
  />
));

export type SelectScrollDownButtonProps =
  RadixSelect.SelectScrollDownButtonProps &
    ThemingProps<'Select'> &
    HTMLChakraProps<'div'>;

export const SelectScrollDownButton = forwardRef<
  SelectScrollDownButtonProps,
  'div'
>((props: SelectScrollDownButtonProps, ref) => (
  <StyledSelectScrollDownButton
    __css={useStyles().scrollDownButton}
    ref={ref}
    {...props}
  />
));
